import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from '@/store/app'
import { useAdminStore } from '@/store/admin'
import { storeToRefs } from 'pinia'
import Parse from '@/services/parse'
import posthog from '@/services/posthog'

function getAppName() {
  const isDev = import.meta.env.VITE_MODE === 'development'
  if (import.meta.env.VITE_MAINTENANCE) {
    return 'maintenance'
  }
  const { hostname } = window.location

  if (hostname.includes('.vercel.app')) {
    const subdomain = new URLSearchParams(window.location.search).get('subdomain')
    if (subdomain && ['app', 'agency', 'admin'].includes(subdomain)) {
      window.localStorage.setItem('subdomain', subdomain)
      window.history.replaceState({}, document.title, window.location.pathname)
      window.location.reload()
    }
    return window.localStorage.getItem('subdomain') || 'app'
  }
  const APP_HOSTS = isDev ? ['app.bruqi.local'] : ['app.bruqi.com', 'app.staging.bruqi.com']
  const ADMIN_HOSTS = isDev ? ['localhost', '0.0.0.0', 'admin.bruqi.local'] : ['admin.bruqi.com', 'admin.staging.bruqi.com']
  if (APP_HOSTS.includes(hostname)) {
    return 'app'
  }
  if (ADMIN_HOSTS.includes(hostname)) {
    return 'admin'
  }
}

const whichApp = getAppName()
let router
export default async function () {
  if (router) { return router }
  if (!whichApp) {
    window.location.href = "https://bruqi.com"
    return
  }
  let routes
  if (whichApp === 'maintenance') {
    routes = await import('./maintenance').then(module => module.default)
  } else {
    if (whichApp === 'app') {
      const { initialize } = useAppStore()
      await initialize()
      routes = await import('./app').then(module => module.default)
    }
    if (whichApp === 'admin') {
      routes = await import('./admin').then(module => module.default)
    }
    routes.push({
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('./common/error-404.vue')
    })
  }

  router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior
  })
  router.beforeEach(beforeEach)
  router.afterEach(afterEach)
  router.onError(onError)
  router.whichApp = whichApp
  return router
}

const scrollBehavior = (to, from, savedPosition) => {
  // do not change scroll position if filter dialog opening / closing
  if (to.hash === '#filter' || from.hash === '#filter') { return }
  if (to.hash) {
    return nextTick(() => {
      const target = document.querySelector(to.hash)
      // offset of 100px and duration 300 smooth
      target && target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    })
  }
  return { top: savedPosition?.y || 0 }
}

const afterEach = (to, from, failure) => {
  if (!failure) {
    const toTitle = [to.meta.title, 'bruqi'].filter(Boolean).join(' | ')
    document.title = toTitle
    nextTick(() => {
      posthog.capture('$pageview', { path: to.fullPath })
    })
  }
}

const globalMiddleware = []

const resolvedMiddleware = {
  // Middleware for the client dashboard
  app: async(to, from, next) => {
    const appStore = useAppStore()
    const { member, agency, account } = storeToRefs(appStore)
    if (!member.value) {
      return next({ name: 'signup', query: { redirect: to.fullPath === '/' ? undefined : to.fullPath } })
    }

    // if the client has no links, redirect to onboarding
    if (account.value && !agency.value && !account.value?.links?.length && to.name !== 'onboarding-links') {
      return next({ name: 'onboarding-links' })
    }

    if (to.meta.accountRequired && !account.value && agency.value) {
      return next({ name: 'index' })
    }
    // if (to.name === 'plans' && agency.value) {
    //   return next({ name: 'index' })
    // }

    // if client is not subscribed, redirect to plans page
    if (['self-report'].includes(to.name)) {
      const locked = account.value && Boolean(account.value.onhold || !account.value.isSubscribed)
      if (locked) {
        return next({ name: 'plans' })
      }
    }

    if (account.value && account.value.onhold && to.name !== 'index') {
      return next({ name: 'index' })
    }
    next()
  },
  appGuest: async (to, from, next) => {
    const { member } = useAppStore()
    if (member) {
      return next({ name: 'index' })
    }
    next()
  },
  admin: async (to, from, next) => {
    const { user } = useAdminStore()
    if (!user && to.name !== 'login') {
      return next({ name: 'login', query: { redirect: to.fullPath === '/' ? undefined : to.fullPath } })
    }
    if (user && to.name === 'login') {
      return next({ name: 'index' })
    }
    const { initialize, initialized } = useAdminStore()
    !initialized && await initialize()
    next()
  },
  guest: (to, from, next) => {
    const { user } = useAdminStore()
    if (user) {
      return next({ name: 'index' })
    }
    next()
  }
}

const beforeEach = async (to, from, next) => {
  const middleware = getMiddleware(to)
  // Call each middleware.
  await callMiddleware(middleware, to, from, (...args) => {
    next(...args)
  })
}

const onError = (error, to) => {
  if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
    window.localStorage.clear()
    window.location.reload()
    return true
  }
  const errorMessage = error.message || error.toString()
  if (
    errorMessage.includes('Failed to fetch dynamically imported module') ||
    errorMessage.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload()
    } else {
      window.location = to.fullPath
    }
  }
}

const getMiddleware = (to) => {
  const middleware = [...globalMiddleware]
  to.matched.filter(record => record.meta && record.meta.middleware).forEach((record) => {
    if (Array.isArray(record.meta.middleware)) {
      middleware.push(...record.meta.middleware)
    } else {
      middleware.push(record.meta.middleware)
    }
  })
  return middleware
}

const callMiddleware = async(middleware, to, from, next) => {
  const stack = middleware.reverse()
  const _next = async (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      return next(...args)
    }
    const middleware = stack.pop()
    if (resolvedMiddleware[middleware]) {
      await resolvedMiddleware[middleware](to, from, _next)
    } else {
      throw new Error(`Undefined middleware [${middleware}]`)
    }
  }
  await _next()
}
